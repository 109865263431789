// export const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const accountingRegex = new RegExp(/^([0-9]+|[0-9]+\.{1}[0-9]{1,2})$/);

export const postalCodeRegex = new RegExp(
  /^[a-zA-Z][0-9][a-zA-Z][ ]{0,1}[0-9][a-zA-Z][0-9]$/
);

export const phoneNumberRegex = new RegExp(
  /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
);

export const numberRegex = new RegExp(/^[0-9]*$/);

export const decimalRegex = new RegExp(/^[0-9]*\.[0-9]{2}$/);
