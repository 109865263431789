import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment, SERVER_URL } from "src/environments/environment";
import { LoginUser, TechSupportData } from "src/app/models/general.model";
import { emailRegex } from "src/app/models/patterns.model";
import { Plugins } from "@capacitor/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

const { Storage } = Plugins;

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  private earlyAccessAllowed = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router: Router) {
    this.checkEarlyAccessAllowed();
  }

  checkEarlyAccessAllowed() {
    Storage.get({ key: "EARLY_ACCESS_ALLOWED" }).then((res) => {
      this.earlyAccessAllowed.next(res.value == "true");
    });
  }

  setEarlyAccessLaunchAllowed(value) {
    this.http
      .get(`${SERVER_URL}/auth/early-access`, {
        params: {
          pc: value,
        },
      })
      .subscribe(
        (result) => {
          if (result["ok"] == true) {
            Storage.set({ key: "EARLY_ACCESS_ALLOWED", value: "true" }).then(
              () => {
                this.earlyAccessAllowed.next(true);
                this.router.navigate(["login"]);
              }
            );
          } else {
            this.router.navigate(["landing"]);
          }
        },
        (error) => {
          this.router.navigate(["landing"]);
          Storage.set({ key: "EARLY_ACCESS_ALLOWED", value: "" }).then(
            this.checkEarlyAccessAllowed
          );
        }
      );
  }

  afterLaunch() {
    const available = new Date("2021-09-01T15:00:00-05:00");
    return (
      !environment.production ||
      this.earlyAccessAllowed.value ||
      Date.now() > available.getTime()
    );
  }

  updateProducts(
    file: File,
    filename: string,
    removeData?: boolean,
    supplier?
  ) {
    var parameters = {
      time: Date.now().toString(),
      removeData: removeData ? "1" : "0",
    };

    if (supplier) {
      parameters["supplier"] = supplier;
    }

    let formData = new FormData();
    formData.append("file", file, filename);
    return this.http.post(`${SERVER_URL}/internal/update/products`, formData, {
      params: parameters,
      headers: {
        Timeout: "120000",
      },
    });
  }

  updateSavings(file: File, filename: string, removeData?: boolean) {
    let formData = new FormData();
    formData.append("file", file, filename);
    return this.http.post(
      `${SERVER_URL}/internal/update/infrastructure`,
      formData,
      {
        params: {
          time: Date.now().toString(),
          removeData: removeData ? "1" : "0",
        },
        headers: {
          Timeout: "120000",
        },
      }
    );
  }

  downloadTemplate() {
    // console.log('template')
    // const url = 'http://www.example.com/file.pdf';
    // this.fileTransfer.download(url, this.file.dataDirectory + 'file.pdf').then((entry) => {
    //   console.log('download complete: ' + entry.toURL());
    // }, (error) => {
    //   // handle error
    // });
  }

  downloadProductCatalog() {
    // console.log('product catalog')
  }

  validateLogin(user: LoginUser) {
    const errors = {
      ok: true,
      email: "",
      password: "",
    };

    if (!user.email || !user.email.match(emailRegex)) {
      errors.ok = false;
      errors.email = "Email address is not valid";
    }

    return errors;
  }

  sendTechSupportRequest(
    data: TechSupportData,
    success: (result) => void,
    error: (error) => void
  ) {
    this.http
      .post(`${SERVER_URL}/city/request-tech-support`, data)
      .subscribe(success, error);
  }

  setLocalObject(key: string, value: string) {
    return Storage.set({ key, value });
  }

  getLocalObject(key: string) {
    return Storage.get({ key });
  }
}
